import React from "react";
import Navbar from "../navs/navbar";
import Footer from "../navs/footer";

const Layout = (props) => {
  return (
    <>
      <div className="container-fluid px-0" style={{ zIndex: "0" }}>
        <Navbar />
        {props.children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
